

































import {Vue, Component} from "vue-property-decorator";
import {MerchantMess} from "@/request/tools/MerchantMess";

@Component({
    name: 'tools_merchantMess'
})
export default class tools_merchantMess extends Vue {
    modal: any = MerchantMess;
    formData: any = {
        title: '',
        content: ''
    };
    formRules: any = {
        title: {required: true, trigger: 'blur'},
        content: {required: true, trigger: 'blur'},
    };
    columns: Array<any> = [
        {
            title: '标题',
            key: 'title',
            minWidth: 160
        },
        {
            title: '内容',
            key: 'content',
            minWidth: 160
        },
        {
            title: '发送时间',
            key: 'createTime',
            minWidth: 128
        }
    ];
    created() {

    }
    createNew() {
        delete this.formData.id;
    }
    edit(row: any) {
        this.formData = row;
    }
    submit() {
        let self: any = this;
        let params = {...self.formData};
        self.$refs['el-form'].validate((valid: boolean) => {
            if (valid) {
                self.$refs['el-table'].doSubmit(params).then(() => {

                });
            }
        })
    }
}
